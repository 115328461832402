import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        baseUrl: process.env.VUE_APP_BASE_URL,
        Sidebar_drawer: null,
        searchPanel: false,
        textToSearch: null,
        resultSearch: null,
        isLogin: false,
        token: null,
        user: null,
        SidebarColor: "#1d2228", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
        SidebarBg: "",
        navbarColor: "white"
    },
    mutations: {
        SetUser(state, payload) {
            state.user = payload;
        },
        SetIslogin(state, payload) {
            state.isLogin = payload;
        },
        SetToken(state, payload) {
            state.token = payload;
        },
        SET_SIDEBAR_DRAWER(state, payload) {
            state.Sidebar_drawer = payload;
        },
        SET_SIDEBAR_COLOR(state, payload) {
            state.SidebarColor = payload;
        },
        SET_NAVBAR_COLOR(state, payload) {
            state.navbarColor = payload;
        },
        resetStateLogin(state) {
            state.isLogin = false;
            state.token = null;
            state.user = null;
        },
        ShowSearchPanel(state) {
            state.searchPanel = true;
        },
        HideSearchPanel(state) {
            state.searchPanel = false;
        },
        ClearSearchText(state) {
            state.textToSearch = null;
        },
        ClearResultSearch(state) {
            state.resultSearch = [];
        },
        SetSearchText(state, payload) {
            state.textToSearch = payload;
        },
        SetResultSearch(state, payload) {
            state.resultSearch = payload;
        }
    },
    actions: {
        clearStateLogin({commit}) {
            commit('resetStateLogin');
        },
        async SearchText(context) {
            let response = await axios.get(`${context.state.baseUrl}/search?value=${context.state.textToSearch}`);
            if (response.data.success) {
                // console.log("response.data:", response.data.result);
                context.commit("SetResultSearch", response.data.result);
            } else {
                context.commit('ClearResultSearch');
            }
        }
    },
    getters: {
        getBaseURL: state => state.baseUrl,
        getUser: state => state.user,
        getToken: state => state.token,
        isLogin: state => state.isLogin,
        getResultSearch: state => state.resultSearch,
        getTextToSearch: state => state.textToSearch,
    },
});