import Vue from 'vue';
import VueRouter from 'vue-router';
// import goTo from "vuetify/es5/services/goto";

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        redirect: "/homepage",
        component: () => import("@/layouts/app-layout/Layout.vue"),
        children: [
            {
                path: "/homepage",
                name: "HomePage",
                component: () => import("@/views/HomePage.vue"),
            },
            {
                path: "/emptypage",
                name: "EmptyPage",
                component: () => import("@/views/EmptyPage.vue"),
            },
            {
                path: '/about-us',
                name: 'About',
                component: () => import('@/views/About.vue')
            },
            {
                path: "/alliance-page",
                name: "AlliancePage",
                component: () => import("@/components/pages/AlliancePage.vue"),
            },
            {
                path: "/market-page",
                name: "MarketPage",
                component: () => import("@/components/pages/MarketPage.vue"),
            },
            {
                path: "/sale-purchase-page",
                name: "SalePurchasePage",
                component: () => import("@/components/pages/SalePurchasePage.vue"),
            },
            {
                path: "/refund",
                name: "RefundPage",
                component: () => import("@/components/pages/RefundPage.vue"),
            },
            {
                path: "/finance",
                name: "FinancePage",
                component: () => import("@/components/pages/FinancePage.vue"),
            },
            {
                path: '/pension',
                name: 'PensionPage',
                component: () => import('@/components/pages/PensionPage.vue')
            },
            {
                path: '/develop',
                name: 'DevelopPage',
                component: () => import('@/components/pages/DevelopPage.vue')
            },
            {
                path: '/events',
                name: 'EventPage',
                component: () => import('@/components/pages/EventPage.vue')
            },
            {
                path: '/terrenazo',
                name: 'TerrenazoPage',
                component: () => import('@/components/pages/TerrenazoPage.vue')
            },
            {
                path: '/rosarito',
                name: 'RosaritoPage',
                component: () => import('@/components/pages/RosaritoPage.vue')
            },
            {
                path: '/sofom',
                name: 'SofomPage',
                component: () => import('@/components/pages/SofomPage.vue')
            },
            {
                path: '/special-projects',
                name: 'SpecialProjects',
                component: () => import('@/views/SpecialProjects.vue')
            },
            {
                path: '/categories',
                name: 'Categories',
                component: () => import('@/views/Categories.vue')
            },
            {
                path: '/categories-profile/:id',
                name: 'CategoryProfile',
                component: () => import('@/views/CategoryProfile.vue')
            },
            {
                path: '/projects',
                name: 'Projects',
                component: () => import('@/views/Projects.vue')
            },
            {
                path: '/briefcase',
                name: 'Briefcase',
                component: () => import('@/views/Briefcase.vue')
            },
            {
                path: '/project-profile/:id',
                name: 'ProjectProfile',
                component: () => import('@/views/ProjectProfile.vue')
            },
            {
                path: "/investor-login",
                name: "InvestorLogin",
                component: () => import("@/views/authentication/InvestorLogin.vue"),
            },
            {
                path: '/investor',
                name: 'Investor',
                component: () => import('@/views/Investor.vue')
            },
            {
                path: '/investor-profile/:id',
                name: 'InvestorProfile',
                component: () => import('@/views/InvestorProfile.vue')
            },
            // {path : '/page/:id?', name='page', component: Page},
        ],
    },
    {
        path: "/authentication",
        redirect: "/formlogin",
        component: () => import("@/layouts/login-layout/LoginLayout.vue"),
        children: [
            {
                path: "/formlogin",
                name: "FormLogin",
                component: () => import("@/views/authentication/FormLogin.vue"),
            },
            {
                path: "/error",
                name: "Error",
                component: () => import("@/views/authentication/Error.vue"),
            },
        ],
    },
    {
        path: "/admin-page",
        redirect: "/admin-user",
        component: () => import("@/layouts/admin-layout/AdminLayout.vue"),
        children: [
            {
                path: "/admin-user",
                name: "AdminUser",
                component: () => import("@/views/admin/AdminUser.vue"),
            },
            {
                path: "/admin-company",
                name: "CompanyModule",
                component: () => import("@/views/admin/CompanyModule.vue"),
            },
            {
                path: "/admin-contacts",
                name: "ContactsModule",
                component: () => import("@/views/admin/ContactsModule.vue"),
            },
            {
                path: "/admin-preferences",
                name: "PreferencesModule",
                component: () => import("@/views/admin/PreferencesModule.vue"),
            },
            {
                path: "/admin-projects",
                name: "ProjectsModule",
                component: () => import("@/views/admin/ProjectsModule.vue"),
            },
            {
                path: "/admin-investors",
                name: "InvestorsModule",
                component: () => import("@/views/admin/InvestorsModule.vue"),
            },
            {
                path: "/admin-advances",
                name: "AdvancesModule",
                component: () => import("@/views/admin/AdvancesModule.vue"),
            },
            {
                path: "/admin-categories",
                name: "CategoriesModule",
                component: () => import("@/views/admin/CategoriesModule.vue"),
            },
            // Secciones de Páginas
            {
                path: "/admin-sliders",
                name: "BannerModule",
                component: () => import("@/views/admin/BannerModule.vue"),
            },
            {
                path: "/admin-slogan",
                name: "SloganModule",
                component: () => import("@/views/admin/SloganModule.vue"),
            },
            {
                path: "/admin-statistic",
                name: "StatisticModule",
                component: () => import("@/views/admin/StatisticModule.vue"),
            },
            {
                path: "/admin-about-us",
                name: "AboutModule",
                component: () => import("@/views/admin/AboutModule.vue"),
            },
            {
                path: "/admin-mision",
                name: "MisionModule",
                component: () => import("@/views/admin/MisionModule.vue"),
            },
            {
                path: "/admin-vision",
                name: "VisionModule",
                component: () => import("@/views/admin/VisionModule.vue"),
            },
            {
                path: "/admin-values",
                name: "ValuesModule",
                component: () => import("@/views/admin/ValuesModule.vue"),
            },
            {
                path: "/admin-alliance",
                name: "AllianceModule",
                component: () => import("@/views/admin/AllianceModule.vue"),
            },
            {
                path: "/admin-market",
                name: "MarketModule",
                component: () => import("@/views/admin/MarketModule.vue"),
            },
            {
                path: "/admin-sale-purchase",
                name: "SalePurchaseModule",
                component: () => import("@/views/admin/SalePurchaseModule.vue"),
            },
            {
                path: "/admin-refund",
                name: "RefundModule",
                component: () => import("@/views/admin/RefundModule.vue"),
            },
            {
                path: "/admin-finance",
                name: "FinanceModule",
                component: () => import("@/views/admin/FinanceModule.vue"),
            },
            {
                path: "/admin-pension",
                name: "PensionModule",
                component: () => import("@/views/admin/PensionModule.vue"),
            },
            {
                path: "/admin-sofom",
                name: "SofomModule",
                component: () => import("@/views/admin/SofomModule.vue"),
            },
            {
                path: "/admin-develop",
                name: "DevelopModule",
                component: () => import("@/views/admin/DevelopModule.vue"),
            },
            {
                path: "/admin-events",
                name: "EventModule",
                component: () => import("@/views/admin/EventModule.vue"),
            },
            {
                path: "/admin-team",
                name: "WorkTeamModule",
                component: () => import("@/views/admin/WorkTeamModule.vue"),
            }
        ],
    },
    {
        path: '/under-construction',
        name: 'UnderConstruction',
        component: () => import('@/views/UnderConstruction.vue')
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    // This is for the scroll top when click on any router link
    scrollBehavior: (to, from, savedPosition) => {
        // let scrollTo = 0;
        console.log('From:', from);
        console.log('To:', to);
        console.log(savedPosition);
    
        if (to.hash) {
            return window.scrollTo({top: document.querySelector(to.hash).offsetTop, behavior: 'smooth'});
        } else {
            return window.scrollTo({top: 0, behavior: 'smooth'});
        }
        /*return {
               selector: to.hash,
               offset: 50,
               behavior: 'smooth',
           }*/
       /* if (to.hash) {
            scrollTo = to.hash;
        } else if (savedPosition) {
            scrollTo = savedPosition.y;
        }
        console.log('scrollTo:', scrollTo);
        return goTo(scrollTo);*/
    },
    // This is for the scroll top when click on any router link
    routes
})

export default router
