import Vue from 'vue';
import App from './App.vue';
import vWow from 'v-wow';
import router from './router';
import store from './store/store';
import vuetify from './plugins/vuetify';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import titleMixin from './mixins/titleMixin';
import "./plugins/vuetify-mask.js";
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.mixin(titleMixin);
Vue.use(VueAxios, axios);
Vue.use(vWow);
Vue.use(VueTelInputVuetify, {
  vuetify,
});
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
